import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import ctaImage from '../../assets/images/ctaimage.png';
import tier1 from '../../assets/images/tier1-image.png';
import tier2 from '../../assets/images/tier2-image.png';
import tier3 from '../../assets/images/tier3-image.png';
import tier4 from '../../assets/images/tier4-image.png';
import mtier1 from '../../assets/images/mtier1-image.png';
import mtier2 from '../../assets/images/mtier2-image.png';
import mtier3 from '../../assets/images/mtier3-image.png';
import { Link } from 'react-router-dom';

import '../../styles/cta-modal.css'


const CTAModal = ({isOpen, toggle}) => {

    const images = [tier1, tier2, tier3, tier4];
    const mimages = [mtier1, mtier2, mtier3];
  
    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalBody>
                <div className='close' onClick={toggle} role='button'>
                    <p>X</p>
                </div>
                <section className='modal-container'>
                    <Row>
                        <Col lg={6} className='modal-content-wrapper'>
                            <h2>10% OFF</h2>
                            <p>When you join the brotherhood rewards program. Gain access to special offers, members-only events and more.</p>
                            <div className="image-container">
                                {images.map((src, index) => (
                                    <img key={index} src={src} alt={`Tier ${index + 1}`} className='tier-image' />
                                ))}
                            </div>
                            <div className="image-container-mobile">
                                {mimages.map((src, index) => (
                                    <img key={index} src={src} alt={`Tier ${index + 1}`} className='tier-image-mobile' />
                                ))}
                            </div>
                            <Link to='/signup' onClick={toggle} className='modal-btn'>
                                Take Me There.
                            </Link>
                        </Col>
                        <Col lg={6}>
                            <img src={ctaImage} alt='' className='modal-image' />
                        </Col>
                    </Row>
                </section>
            </ModalBody>
        </Modal>
    )
}

export default CTAModal