import React, {useState, useEffect} from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Routers from '../../routers/Routers';
import CTAModal from '../Modals/CTAModal';
import { useLocation } from 'react-router-dom';

const Layout = () => {
    const currentUser = localStorage.getItem('user');
    const [isModalOpen, setModalOpen] = useState(false);

    const location = useLocation();
    const isAdminDashboard = location.pathname.startsWith('/admin-dashboard');

    useEffect(() => {
        if (!currentUser) {
            const lastShown = localStorage.getItem('lastModalShown');
            const now = new Date().getTime();

            if (!lastShown || (now - lastShown) > 6 * 60 * 60 * 1000) {
                const timer = setTimeout(() => {
                  setModalOpen(true);
                }, 4000); 

                return () => clearTimeout(timer); 
            }
        }
    }, [currentUser]);

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
        if (!isModalOpen) {
            localStorage.setItem('lastModalShown', new Date().getTime());
        }
    };

    return <>
        {!isAdminDashboard && <Header />}
        <div>
            <Routers />
        </div>
        {!isAdminDashboard && <Footer />}
        <CTAModal isOpen={isModalOpen} toggle={toggleModal} />
    </>
}

export default Layout