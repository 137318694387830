import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import './carousel.css';

const Carousel = ({imageUrls}) => {
  return (
        <>
            <div className='my-swiper'>
                <Swiper
                    slidesPerView={1}
                    navigation={true}
                    modules={[Navigation]}
                    loop
                    className='mySwiper'
                >
                    {imageUrls.map((url, index) => (
                        <SwiperSlide key={index}>
                            <div className="swiper-zoom-container">
                                <img src={url} alt={`Product ${index}`} className='product-imagge' />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    )
}

export default Carousel