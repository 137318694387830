import React, { useEffect, useState } from 'react';
import { useAuth } from '../custom-hooks/useAuth';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import AdminDashboard from '../pages/dashboard/AdminDashboard';
import { jwtDecode } from 'jwt-decode';
import { auth } from '../firebase.config';

const ProtectedAdminRoute = () => {
    const currentUser = auth.currentUser;
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [loading, setLoading] = useState(true);  // Add loading state
    const location = useLocation();

    useEffect(() => {
        const checkSuperAdmin = async () => {
            if (currentUser) {
                try {
                    const idToken = await currentUser.getIdToken();
                    const decodedToken = jwtDecode(idToken);

                    // Check for custom claim "isSuperAdmin"
                    console.log("isSuperAdmin: " + decodedToken.isSuperAdmin);
                    const isSuperAdmin = decodedToken.isSuperAdmin || false;
                    console.log("isSuperAdmin: " + isSuperAdmin);
                    setIsSuperAdmin(isSuperAdmin);
                } catch (error) {
                    console.error('Error getting or decoding token:', error);
                }
            }
            setLoading(false);  // Set loading to false after the check
        };

        checkSuperAdmin();
    }, [currentUser]);

    // If the check is still loading, return a loading indicator or null
    if (loading) {
        return <div>Loading...</div>;  // Or any loading spinner you prefer
    }

    // If no user is logged in, redirect to login
    if (!currentUser) {
        return <Navigate to='/login' replace state={{ from: location }} />;
    }

    // If the user is not a super admin, redirect to home (or any other route)
    if (!isSuperAdmin) {
        console.log("User is not a super admin, redirecting...");
        return <Navigate to='/home' replace state={{ from: location }} />;
    }

    console.log("User is a super admin, rendering dashboard...");

    // If the user is a super admin, allow access to the protected route
    return <AdminDashboard />;
};

export default ProtectedAdminRoute;
