import React, {useState} from 'react';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    
} from 'reactstrap';
import './customAccordion.css';

const CustomAccordion = ({accordionData}) => {
    const [open, setOpen] = useState('');
    const toggle = (id) => {
      if (open === id) {
        setOpen('');
      } else {
        setOpen(id);
      }
    };
    return (
        <div className="my-accordion">
            <Accordion open={open} toggle={toggle} className='my-accordion'>
                {accordionData.map((item, index) => (
                <AccordionItem key={index} className="accordion-item">
                    <AccordionHeader targetId={index.toString()} className='accordion-header'>
                        <span className='accordion-title'>{item.title}</span>
                    </AccordionHeader>
                    <AccordionBody accordionId={index.toString()}>
                        <p className='accordion-details'>{item.content}</p>
                    </AccordionBody>
                </AccordionItem>
              ))}
            </Accordion>
        </div>
    );
};

export default CustomAccordion