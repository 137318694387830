import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useSelector } from 'react-redux';

import CartProduct from '../UI/CartProduct';

import '../../styles/cart-modal.css'
import { Link } from 'react-router-dom';

const CartModal = ({isOpen, toggle}) => {

    const cartItems = useSelector(state => state.cart.cartItems)
    const totalAmount = Number(useSelector((state) => state.cart.totalAmount));

    return <Modal isOpen={isOpen} toggle={toggle} className='cart-modal'>
                <ModalHeader className='cart-header' toggle={toggle}>Your Cart</ModalHeader>
                {
                    cartItems.length === 0 ? (<span className='modal-message'> No item added to the cart. </span>) : 
                    (
                        <>
                            <ModalBody>
                                <CartProduct cartItems={cartItems} />
                            </ModalBody>
                            
                            <ModalFooter>
                                <div className='subtotal'>
                                    <p>Subtotal</p>
                                    <p class='fw-bold'>$ {totalAmount.toFixed(2)} USD</p>
                                </div>

                                <Link to='' className='modal-btn btn btn-secondary' onClick={toggle}>
                                    Pay with browser.
                                </Link>
                                
                                <Link to='/checkout' className='modal-btn btn btn-secondary' onClick={toggle}>
                                    Continue to Checkout
                                </Link>
                            </ModalFooter>
                        </>
                    )
                }
                
        </Modal>
}

export default CartModal