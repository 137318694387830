import React, {useState, useEffect} from 'react'
import Helmet from '../components/Helmet/Helmet'
import { Container } from 'reactstrap';
import '../styles/privacy-policy.css';
import TableOfContents from '../components/TableofContents/TableofContents';

const PrivacyPolicy = () => {
    const [headings, setHeadings] = useState([]);

    useEffect(() => {
    // Select only h4 elements
        const headingElements = Array.from(document.querySelectorAll('h4'));
        const newHeadings = headingElements.map((heading, index) => ({
            id: `heading-${index + 1}`,
            text: heading.textContent,
            level: 4
        }));

        // Assign sequential IDs to headings
        headingElements.forEach((heading, index) => {
            heading.id = `heading-${index + 1}`;
        });

        setHeadings(newHeadings);
    }, []);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Helmet title={'Privacy Policy'}>
            <div className='p-policy'>
                <section>
                    <Container>
                        <h1>PRIVACY POLICY</h1>
                        <p style={{fontWeight: 'bold', marginBottom: '3rem'}}>Last updated March 28, 2024</p>
                        <p>This privacy notice for THEVISIONGARMENTS LLC ("<b>we</b>," "<b>us</b>," or "<b>our</b>"), describes how and why we might collect, store, use, and/or share ("<b>process</b>") your information when you use our services ("<b>Services</b>"), such as when you:</p>
                        <ul className='custom-list'>
                            <li>Visit our website at <a href='https://www.lordvisiongarments.com'>https://www.lordvisiongarments.com</a>, or any website of ours that links to this privacy notice</li>
                            <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                        </ul>
                        <p>
                            <span style={{fontWeight: 'bold'}}>Questions or concerns?</span> Reading this privacy notice will help you understand your privacy rights and choices. 
                            If you do not agree with our policies and practices, please do not use our Services. 
                            If you still have any questions or concerns, please contact us at thevisiongarments00@gmail.com.
                        </p>

                        <h2>SUMMARY OF KEY POINTS</h2>

                        <p><em><b>This summary provides key points from our privacy notice, but you can find out more details about any of these topics 
                        by clicking the link following each key point or by using our <a onClick={() => scrollToSection('#toc')}>table of contents</a> below to find the section you are looking for.</b></em></p>

                        <p>
                            <b>What personal information do we process?</b> When you visit, use, or navigate our Services, we may process personal information 
                            depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about <a onClick={() => scrollToSection('subheading-11')}>personal information you disclose to us</a>.
                        </p>

                        <p>
                            <b>Do we process any sensitive personal information?</b> We do not process sensitive personal information.
                        </p>

                        <p>
                            <b>Do we receive any information from third parties?</b> We do not receive any information from third parties.
                        </p>

                        <p>
                            <b>How do we process your information?</b> We process your information to provide, improve, and administer our Services, 
                            communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. 
                            We process your information only when we have a valid legal reason to do so. Learn more about <a onClick={() => scrollToSection('heading-2')}>how we process your information</a>.
                        </p>

                        <p>
                            <b>In what situations and with which parties do we share personal information?</b> We may share information in specific situations and with specific third parties. Learn more about <a onClick={() => scrollToSection('heading-4')}>when and with whom we share your personal information</a>.
                        </p>

                        <p>
                            <b>What are your rights?</b> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about <a onClick={() => scrollToSection('heading-7')}>your privacy rights</a>.
                        </p>

                        <p>
                            <b>How do you exercise your rights?</b> The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
                        </p>

                        <p>Want to learn more about what we do with any information we collect? <a onClick={() => scrollToSection('#toc')}>Review the privacy notice in full</a>.</p>


                        <h2 id='#toc'>TABLE OF CONTENTS</h2>

                        <TableOfContents headings={headings} />

                        <h4 style={{marginTop: '2.5rem'}}>1. WHAT INFORMATION DO WE COLLECT?</h4>

                        <span id='subheading-11' style={{fontWeight: 'bold', lineHeight: '4em'}}>Personal information you disclose to us</span>

                        <p><em><b>In Short</b></em>: We collect personal information that you provide to us.</p>
                        <p>We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
                        <p><b>Personal Information Provided by You.</b> The personal information that we collect depends on the context of your interactions with us and the Services, 
                        the choices you make, and the products and features you use. The personal information we collect may include the following:</p>

                        <ul className='custom-list'>
                            <li>names</li>
                            <li>phone numbers</li>
                            <li>email addresses</li>
                            <li>mailing addresses</li>
                            <li>billing addresses</li>
                            <li>debit/credit card numbers</li>
                        </ul>

                        <p><b>Sensitive Information.</b> We do not process sensitive information.</p>
                        <p><b>Payment Data.</b> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, 
                        and the security code associated with your payment instrument. All payment data is stored by Stripe. 
                        You may find their privacy notice link(s) here: <a href='https://stripe.com/privacy'>https://stripe.com/privacy</a>.</p>

                        <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>

                        <span className='inline-heading'>Information automatically collected</span>

                        <p><em><b>In Short</b></em>: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</p>

                        <p>We automatically collect certain information when you visit, use, or navigate the Services. 
                        This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, 
                        such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, 
                        information about how and when you use our Services, and other technical information. 
                        This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>

                        <p>Like many businesses, we also collect information through cookies and similar technologies.</p>

                        <ul className='custom-list'>
                            <li><p> <em>Log and Usage Data. </em>Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. 
                            Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services
                            (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings). </p></li>
                        </ul>


                        <h4>2. HOW DO WE PROCESS YOUR INFORMATION?</h4>

                        <p><b><em>In Short</em></b>: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</p>
                        <p><b>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</b></p>

                        <div style={{marginBottom: '2rem'}}>
                        <ul className='custom-list'>
                            <li><b>To deliver and facilitate delivery of services to the user</b>. We may process your information to provide you with the requested service.</li>
                            <li><b>To respond to user inquiries/offer support to users</b>. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
                            <li><b>To fulfill and manage your orders</b>. We may process your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.</li>
                            <li><b>To enable user-to-user communications</b>. We may process your information if you choose to use any of our offerings that allow for communication with another user.</li>
                            <li><b>To save or protect an individual’s vital interest</b>. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</li>
                        </ul>
                        </div>

                        <h4>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h4>

                        <p><b><em>In Short</em></b>: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, 
                        like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, 
                        or to fulfill our legitimate business interests.</p>

                        <p><b><em><u>If you are located in the EU or UK, this section applies to you.</u></em></b></p>

                        <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. 
                        As such, we may rely on the following legal bases to process your personal information:</p>

                        <ul className='custom-list'>
                            <li><b>Consent.</b> We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. 
                            You can withdraw your consent at any time. Learn more about <a onClick={() => scrollToSection('wyc')}>withdrawing your consent.</a></li>
                            <li><b>Performance of a Contract.</b> We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, 
                            including providing our Services or at your request prior to entering into a contract with you.</li>
                            <li><b>Legal Obligations.</b> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, 
                            exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</li>
                            <li><b>Vital Interests.</b> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, 
                            such as situations involving potential threats to the safety of any person.</li>
                        </ul>

                        <p><b><em><u>If you are located in Canada, this section applies to you.</u></em></b></p>

                        <p>We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, 
                        or in situations where your permission can be inferred (i.e., implied consent). You can <a onClick={() => scrollToSection('wyc')}>withdraw your consent.</a> at any time.</p>

                        <p>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</p>

                        <ul className='custom-list'>
                            <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
                            <li>For investigations and fraud detection and prevention</li>
                            <li>For business transactions provided certain conditions are met</li>
                            <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</li>
                            <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
                            <li>If we have reasonable grounds to believe an individual has been, is, or may be a victim of financial abuse</li>
                            <li>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the 
                            collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</li>
                            <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</li>
                            <li>If it was produced by an individual in the course of their employment, business, 
                            or profession and the collection is consistent with the purposes for which the information was produced</li>
                            <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
                            <li>If the information is publicly available and is specified by the regulations</li>
                        </ul>

                        <h4>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4>

                        <p><b><em>In Short</em></b>: We may share information in specific situations described in this section and/or with the following third parties.</p>

                        <p>We may need to share your personal information in the following situations:</p>

                        <ul className='custom-list'>
                            <li><b>Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, any merger, 
                            sale of company assets, financing, or acquisition of all or a portion of our business to another company</li>
                            <li><b>When we use Google Maps Platform APIs.</b> We may share your information with certain Google Maps Platform APIs 
                            (eg., Google Maps API, Places API) We use certain Google Maps Platform APIs to retrieve certain information when you make location-specific requests. 
                            This includes: and other similar information. A full list of what we use information for can be found in this section and in the previous section titled 
                            "<a onClick={() => scrollToSection('heading-2')}>HOW DO WE PROCESS YOUR INFORMATION?</a>" We obtain and store on your device ("cache") your Incation for months. 
                            You may revoke your consent anytime by contacting us at the contact details provided at the end of this document. 
                            The Google Maps Platform APIs that we use store and access cookies and other information on your devices. If you are a user currently in the European Economic Area 
                            (EU countries, Iceland, Liechtenstein, and Norway) or the United Kingdom, please take a look at our Cookie Notice</li>
                        </ul>

                        <h4>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>

                        <p><b><em>In Short</em></b>: We may use cookies and other tracking technologies to collect and store your information.</p>
                        <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. 
                        Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice</p>

                        <h4>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>

                        <p><b><em>In Short</em></b>: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law</p>
                        <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, 
                        unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).</p>
                        <p>When we have no ongoing legitimate business need to process your personal information, we will either desele or anonymize such information, or, 
                        if this is not possible (for example because your personal information has been stored in backup archives), 
                        then we will securely store your personal information and isolate it from any further processing until deletion is possible</p>

                        <h4>7. WHAT ARE YOUR PRIVACY RIGHTS?</h4>

                        <p><b><em>In Short</em></b>: in some regions, such as the European Economic Area (EEA). United Kingdom (UK). Switzerland, and Canada, 
                        you have rights that allow you greater access to and control overyour personal information. You may review, change, or terminate your account at any time.</p>

                        <p>In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right 
                        (i) to request access and obtain a copy of your personal information, 
                        (ii) to request rectification or erasure; 
                        (iii) to restrict the processing of your personal information; 
                        (iv) if applicable, to data portability; and 
                        (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. 
                        You can make such a request bycontacting us by using the contact details provided in the section "<a onClick={() => scrollToSection('heading-11')}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below.</p>

                        <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>

                        <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, 
                        you also have the night to complain to your <a href='https://ec.europa.eu/newsroom/article29/items/612080'>Member State data protection authority or UK data protection authority</a>.</p>

                        <p>If you are located in Switzerland, you may contact the <a href='https://www.edoeb.admin.ch/edoeb/en/home.html'>Federal Data Protection and Information Commissioner</a>.</p>

                        <p id='wyc'><b><u>Withdrawing your consent</u></b>: if we are relying on your consent to process your personal information, 
                        which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. 
                        You can withdraw your consent at any time by contacting us by using the contact details provided in the section "<a onClick={() => scrollToSection('heading-11')}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below.</p>

                        <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, 
                        will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>

                        <p><b><u>Opting out of marketing and promotional communications:</u></b> You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, 
                        or by contacting us using the details provided in the section "<a onClick={() => scrollToSection('heading-11')}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below. You will then be removed from the marketing lists. However we may still communicate with you for example, 
                        to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</p>

                        <p><b><u>Cookies and similar technologies</u></b>: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. 
                        If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.</p>

                        <p>If you have questions or comments about your privacy rights, you may email us at thevisiongarments00@gmail.com</p>

                        <h4>8. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>

                        <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. 
                        At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. 
                        If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>

                        <h4>9. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h4>

                        <p><b><em>In Short</em></b>: If you are a resident of, you are granted specific rights regarding access to your personal information.</p>

                        <p><b>What categories of personal information do we collect?</b></p>

                        <p>We have collected the following categories of personal information in the past twelve (12) months:</p>

                        <table className='category-table'>
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Examples</th>
                                    <th>Collected</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>A. Identifiers</td>
                                    <td>Contact details, such as real name, alias, postal address, telephone or mobile contact number, 
                                    unique personal identifier, online identifier, Internet Protocol address, email address, and account name</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>B. Protected classification characteristics under state or federal law</td>
                                    <td>Gender and date of birth</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>C. Commercial information</td>
                                    <td>Transaction information, purchase history, financial details, and payment information</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>D. Biometric information</td>
                                    <td>Fingerprints and voiceprints</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>E. Internet or other similar network activity</td>
                                    <td>Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements.</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>F. Geolocation data</td>
                                    <td>Device location</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>G. Audio, electronic, visual, thermal, olfactory, or similar information</td>
                                    <td>Images and audio, video or call recordings created in connection with our business activities</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>H. Professional or employment-related information</td>
                                    <td>Business contact details in order to provide you our Services at a business level or job title, 
                                    work history, and professional qualifications if you apply for a job with us</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>I. Education Information</td>
                                    <td>Student records and directory information</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>J. Inferences drawn from collected personal information</td>
                                    <td>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual's preferences and characteristics</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>K. Sensitive personal Information</td>
                                    <td>Transaction information, purchase history, financial details, and payment information</td>
                                    <td>NO</td>
                                </tr>
                            </tbody>
                        </table>

                        <p>We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</p>
                        <ul className='custom-list'>
                            <li>Receiving help through our customer support channels;</li>
                            <li>Participation in customer surveys or contests; and</li>
                            <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
                        </ul>

                        <p><b>How do we use and share your personal information?</b></p>

                        <p>Learn about how we use your personal information in the section, "<a onClick={() => scrollToSection('heading-2')}>HOW DO WE PROCESS YOUR INFORMATION?</a>"</p>

                        <p><b>Will your information be shared with anyone else?</b></p>

                        <p>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. 
                        Learn more about how we disclose personal information to in the section, "<a onClick={() => scrollToSection('heading-4')}>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>"</p>

                        <p>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. 
                        This is not considered to be "selling" of your personal information..</p>

                        <p>We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. 
                        We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.</p>

                        <h4>10. DO WE MAKE UPDATES TO THIS NOTICE?</h4>
                        <p><b><em>In Short</em></b>: Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>

                        <p>We may update this privacy notice from time to time. 
                        The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. 
                        If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. 
                        We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>

                        <h4>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>

                        <p>If you have questions or comments about this notice, you may email us at thevisiongarments00@gmail.com or contact us by post at:</p>

                        <p>THEVISIONGARMENTS LLC<br />
                        810 Spartan Drive<br /> 
                        Missoula, MT 59801<br /> 
                        United States</p>

                        <h4>12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h4>

                        <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. 
                        To request to review, update, or delete your personal information, please fill out and submit a <a href=''>data subject access request</a>.</p>

                    </Container>
                </section>
            </div>
        </Helmet>
    )
}

export default PrivacyPolicy;