import React, {useState} from 'react';
import Helmet from '../../components/Helmet/Helmet';
import { Row, Col, Form, FormGroup, Button } from 'reactstrap';

import signup1 from '../../assets/images/signup1.png';
import signup2 from '../../assets/images/signup2.png';
import signup3 from '../../assets/images/signup3.png';

import '../../styles/signup.css'
import { Link, useNavigate } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';

import { createUserWithEmailAndPassword  } from "firebase/auth";
import { setDoc, doc } from 'firebase/firestore';

import { auth, db } from '../../firebase.config';

import { toast } from 'react-toastify'

const Signup = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [isAgreedtoTandC, setisAgreedtoTandC] = useState(false);
    const [isOver13, setisOver13] = useState(false);
    const [isEmailSubscribe, setisEmailSubscribe] = useState(true);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    
    const handleTandCCheckboxChange = (e) => {
        setisAgreedtoTandC(e.target.checked);
    };
    const handleOver13heckboxChange = (e) => {
        setisOver13(e.target.checked);
    };
    const handleEmailSubCheckboxChange = (e) => {
        setisEmailSubscribe(e.target.checked);
    };

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        if (!dateOfBirth) {
        setIsFocused(false);
        }
    };

    const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        // Adjust the age if the birthday hasn't occurred yet this year
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            return age - 1;
        }
        return age;
    };

    const validatePassword = (password) => {
        const passwordPattern = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,}$/;
        return passwordPattern.test(password);
    };

    const validateForm = () => {
        // Check required fields
        if (
            firstName.trim() === '' ||
            lastName.trim() === '' ||
            email.trim() === '' ||
            dateOfBirth === '' ||
            phoneNumber === '' ||
            password.trim() === '' ||
            confirmPassword.trim() === ''
        ) {
            toast.error('Please fill in all required fields.');
            return false;
        }

        if (!isAgreedtoTandC) {
            toast.error("You must agree to terms and conditions");
            return false;
        }
        
        const age = calculateAge(dateOfBirth);

        if (!isOver13 || age < 13 ) {
            toast.error("You must be at least 13 years old to sign up.");
            return false;
        }

        // Validate phone number format before proceeding
        const phoneNumberPattern = /^\d{10,}$/;
        if (phoneNumber && !phoneNumberPattern.test(phoneNumber)) {
            toast.error('Invalid phone number format, enter valid phone number');
            return false;
        }

        if (!validatePassword(password)) {
            toast.error('Password must contain at least 8 alphanumeric characters.');
            return;
        }

        // Check if passwords match
        if (password !== confirmPassword) {
            toast.error('Passwords do not match.');
            return false;
        }

        return true;
    };

    const signup = async(e) => {
        const newMemberPoint = 10;
        e.preventDefault()

        if (!validateForm()) {
            return;
        }

        setLoading(true);

        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth, 
                email, 
                password
            );
            const user = userCredential.user;
            
            // Store additional information in Firestore
            const userDocRef = doc(db, "users", user.uid); // Create a document with the user's UID
            await setDoc(userDocRef, { 
                email: user.email,
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                dateOfBirth,
                phoneNumber: phoneNumber,
                address: '',
                points: newMemberPoint,
                isAgreedtoTandC,
                isEmailSubscribe,
                isOver13,
                isFirstLogin: false,
                createdAt: new Date()
            });

            if (isEmailSubscribe) {
                try{
                    await fetch('https://addtoklaviyolist-tyiughjr4a-uc.a.run.app', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            email,
                            firstName,
                            lastName,
                        })
                    }); 
                } catch (error) {
                    console.error('Error adding to Klaviyo list:', error.message);
                }
            }
            
            setLoading(false);
            toast.success("Account created successfully")
            navigate('/login')
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                toast.error('Email is already in use. Please use a different email.');
            } else if (error.code === 'auth/weak-password') {
                toast.error('Password should be at least 6 characters.');
            } else if (error.code === 'auth/invalid-phone-number') {
                toast.error('Invalid phone number. Please enter a valid phone number.');
            } else toast.error('something went wrong during signup', error);
        } finally {
            setLoading(false);
        }
    }
    return (
        <Helmet>
            <section className='p-5 signup-wrapper'>
                <Row>
                    <Col lg={8} sm={12}>
                        <div className='signup-header'>
                            <h2>Create a New Brotherhood Account</h2>
                            <p>Register below to start receiving all kinds of benefits, including membership to our BROTHERHOOD Rewards Loyalty program</p>
                            <span>Already have an account? <u><Link role='button' to='/login'>login.</Link></u></span>
                        </div>
                    </Col>
                </Row>

                <Row className='mt-5 mb-5'>
                    <Col md={4} className='d-flex flex-column align-items-center'>
                        <img src={signup1} alt={signup1} />
                        <p>Earn 1 point for every 1 dollar spent, plus access to exclusive double & triple point shopping events.</p>
                    </Col>

                    <Col md={4} className='d-flex flex-column align-items-center'>
                        <img src={signup2} alt={signup2} />
                        <p>Gain Access to Exclusive members-only products & offers.</p>
                    </Col>

                    <Col md={4} className='d-flex flex-column align-items-center'>
                        <img src={signup3} alt={signup3} />
                        <p>Save Shipping & Payment Info for a faster Checkout Experience. Access Your Wish Lists & Order History.</p>
                    </Col>
                </Row>

                <div>
                    <Form onSubmit={signup} >
                        <Row>
                            <p className='required'>*Required</p>
                            <Col md={6} sm={12}>
                                <FormGroup className='auth-form'>
                                    <input 
                                        type='text' 
                                        placeholder='*First Name'
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        className=''/>
                                </FormGroup>
                            </Col>

                            <Col md={6} sm={12}>
                                <FormGroup className='auth-form'>
                                    <input 
                                        type='text' 
                                        placeholder='*Last Name'
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <FormGroup className='auth-form'>
                                    <input 
                                        type='email' 
                                        placeholder='*Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} sm={12}>
                                <FormGroup className='auth-form'>
                                    <input 
                                        type={isFocused ? 'date' : 'text'}
                                        placeholder={!isFocused ? '*Date of Birth' : ''}
                                        value={dateOfBirth}
                                        className='date-input'
                                        onChange={(e) => setDateOfBirth(e.target.value)}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={6} sm={12}>
                                <FormGroup className='auth-form'>
                                    <input 
                                        type='tel'
                                        placeholder='*Phone Number' 
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} sm={12}>
                                <FormGroup className='auth-form'>
                                    <label for='phone' />
                                    <input 
                                        type='password' 
                                        placeholder='*Password' 
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={6} sm={12}>
                                <FormGroup className='auth-form'>
                                    <input 
                                        type='password' 
                                        placeholder='*Confirm Password' 
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup className='form-group'>
                            <div className='radio-input'>
                                <input
                                    className=''
                                    type='checkbox'
                                    role='button'
                                    checked={isAgreedtoTandC}
                                    onChange={handleTandCCheckboxChange}
                                ></input>
                                <label>*I agree to the Brotherhood Rewards <span>Terms and Conditions</span>, The <span>Privacy Policy</span>, and the <span>Terms of Use</span></label>
                            </div>
                        </FormGroup>

                        <FormGroup className='form-group'>
                            <div className='radio-input'>
                                <input
                                    type='checkbox'
                                    role='button'
                                    checked={isOver13}
                                    onChange={handleOver13heckboxChange}
                                ></input>
                                <label>*Yes, I am over 13 years old</label>
                            </div>
                        </FormGroup>

                        <FormGroup className='form-group'>
                            <div className='radio-input'>
                                <input
                                    type='checkbox'
                                    role='button'
                                    checked={isEmailSubscribe}
                                    onChange={handleEmailSubCheckboxChange}
                                    disabled
                                ></input>
                                <label>*Keep me updated on exclusives</label>
                            </div>
                        </FormGroup>
                        <div className='d-flex justify-content-center pt-4'><Button className='btn-signup' title='Sign in'>
                        {loading 
                            ? (<MoonLoader color="#fff" size={20}  />)
                            : ("Create an Account")
                        }                            
                        </Button></div>

                    </Form>
                </div>
            </section>
        </Helmet>
    )
}

export default Signup