import React, {useState} from 'react';

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
  } from 'reactstrap';
import './productAccordion.css';

const ProductAccordion = ({accordionData}) => {

    const [open, setOpen] = useState('');
    const toggle = (id) => {
      if (open === id) {
        setOpen('');
      } else {
        setOpen(id);
      }
    };

    return (
        <div className="product-accordion">
            <Accordion flush open={open} toggle={toggle} className='my-accordion'>
                {accordionData.map((item, index) => (
                <AccordionItem key={index}  className="product_accordion-item">
                    <div className='product_accordion-button'>
                        <AccordionHeader targetId={index.toString()} className='product_accordion-header'>
                            <span className='accordion-title'>{item.title}</span>
                        </AccordionHeader>
                        <AccordionBody className='product_accordion-body mb-1' accordionId={index.toString()}>
                            <p className='accordion-details'>{item.content}</p>
                        </AccordionBody>
                    </div>
                </AccordionItem>
                ))}
            </Accordion>
        </div>
    )
}

export default ProductAccordion