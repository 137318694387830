import React, {useEffect} from 'react'

const Helmet = ({ title, description, children }) => {
    // document.title = 'lordvisiongarments - ' + props.title

    useEffect(() => {
        document.title = 'lordvisiongarments - ' + title;

        if (description) {
            let metaDescription = document.querySelector('meta[name="description"]');

            if (metaDescription) {
                metaDescription.setAttribute('content', description);
            } else {
                metaDescription = document.createElement('meta');
                metaDescription.name = 'description';
                metaDescription.content = description;
                document.head.appendChild(metaDescription);
            }
        }
    }, [title, description]);
  return (
    <div className='w-100'>{children}</div>
  )
}

export default Helmet