import React from 'react';
import {useAuth} from '../custom-hooks/useAuth';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
    const {currentUser} = useAuth();
    const location = useLocation();

    return currentUser ? <Outlet /> : <Navigate to='/login' replace state={{from: location}}/>
}

export default ProtectedRoute