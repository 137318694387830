import React from 'react';
import { motion } from 'framer-motion';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify'; 

import { useDispatch } from 'react-redux';
import { cartActions } from '../../redux/slices/cartSlice';

import '../../styles/product-card.css';


const ProductCard = ({item}) => {
    const dispatch = useDispatch();

    const addToCart = () => {
        dispatch(cartActions.addItem({
            id: item.id,
            productName: item.productName,
            price: item.price,
            imgUrl: item.imgUrl, 
        }));

        toast.success('Product added to successfully')
    }

    return (
        <Col className='col-6' lg={3} md={6} sm={6}>
            <div className='product-item'>
                <Link to={`/productDetails/${item.id}`}>
                    <div className='product-image-container'>
                        <motion.img whileHover={{scale: 0.9}} src={item.imgUrl[0]} className='product-image' alt={item.productName} />
                    </div>
                 </Link>
                <div className='product-info'>
                    <h3 className='product-name'><Link to={`/productDetails/${item.id}`}>{item.productName.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())}</Link></h3>
                </div>
                <div className='product_card-bottom d-flex align-items-center justify-content-between'>
                    <span className='price'>${item.price}</span>
                    {item.sizes && item.sizes.length === 0 && (
                        <motion.span role='button' whileTap={{scale: 1.2}} onClick={addToCart}>
                            <i class="ri-add-line"></i>
                        </motion.span>
                    )}
                </div>
            </div>
        </Col>
    )
}

export default ProductCard