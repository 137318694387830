import { Route, Routes, Navigate } from 'react-router-dom';

import React from 'react';
import Home from '../pages/Home';
import ProductDetails from '../pages/ProductDetails';
import Login from '../pages/auth/Login';
import Signup from '../pages/auth/Signup';
import FAQ from '../pages/FAQ';
import Contact from '../pages/Contact';
import Retina from '../pages/Retina';
import Brotherhood from '../pages/Brotherhood';
import Checkout from '../pages/Checkout';
import ProtectedRoute from './ProtectedRoute';
import ProtectedAdminRoute from './ProtectedAdminRoute'
import AdminDashboard from '../pages/dashboard/AdminDashboard'
import Profile from '../pages/Profile';
import { AuthProvider } from '../custom-hooks/useAuth';
import ForgortPassword from '../pages/auth/ForgortPassword';
import Grotto from '../pages/Grotto';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import ScrollToTop from '../components/Helpers/ScrollToTop';
import ManageUsers from '../pages/dashboard/dashboard-pages/ManageUsers';
import ManageOrders from '../pages/dashboard/dashboard-pages/ManageOrders';
import ManageProducts from '../pages/dashboard/dashboard-pages/ManageProducts';
import Payments from '../pages/dashboard/dashboard-pages/Payments';

const Routers = () => {
    return (
    <AuthProvider>
        <ScrollToTop />
        <Routes>
            <Route path='/' element={<Navigate to='/home' />} />
            <Route path = 'home' element={<Home/>} />
            <Route path = 'productDetails/:id' element={<ProductDetails/>} />
            <Route path = 'login' element={<Login/>} />
            <Route path = 'signup' element={<Signup/>} />
            <Route path = 'forgotPassword' element={<ForgortPassword/>} />
            <Route path = 'faq' element={<FAQ/>} />
            <Route path = 'contact' element={<Contact/>} />
            <Route path = 'retina' element={<Retina/>} />
            <Route path = 'brotherhood' element={<Brotherhood/>} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="grotto" element={<Grotto />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            
            <Route element={<ProtectedRoute />}>
                <Route path="/profile" element={<Profile />} />
            </Route>

            {/* Admin Route (Protected for Super Admin) */}
            <Route path="/admin-dashboard" element={<ProtectedAdminRoute />}>
                <Route path="manage-users" element={<ManageUsers />} />
                <Route path="manage-products" element={<ManageProducts />} />
                <Route path="manage-orders" element={<ManageOrders />} />
                <Route path="payments" element={<Payments />} />
            </Route>
        </Routes>
    </AuthProvider>
    )
}

export default Routers