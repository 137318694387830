import React, { useState, useEffect } from 'react';
import { db, storage } from '../../../firebase.config'; // Assuming Firebase config is in this file
import { collection, addDoc, updateDoc, doc, deleteDoc, getDocs } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; 
import '../../../styles/manageProducts.css'; // Add your CSS file for styles

const ManageProducts = () => {
    const [products, setProducts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [productData, setProductData] = useState({
        category: '',
        details: '',
        imgUrl: [],
        price: '',
        productName: '',
        productType: '',
        returns: '',
        shipping: '',
        sizes: [],
    });
    const [editProductId, setEditProductId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploading, setUploading] = useState(false);

    // Fetch all products
    const fetchProducts = async () => {
        const productsRef = collection(db, "products");
        const querySnapshot = await getDocs(productsRef);
        setProducts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setLoading(false);
    };

    // Add product
    const addProduct = async () => {
        try {
            const productsRef = collection(db, "products");
            await addDoc(productsRef, productData);
            setShowModal(false);
            fetchProducts();
        } catch (error) {
            console.error("Error adding product:", error);
        }
    };

    // Edit product
    const editProduct = async () => {
        try {
            const productRef = doc(db, "products", editProductId);
            await updateDoc(productRef, productData);
            setShowModal(false);
            fetchProducts();
        } catch (error) {
            console.error("Error editing product:", error);
        }
    };

    // Delete product
    const deleteProduct = async (id) => {
        const confirmation = window.confirm("Are you sure you want to delete this product?");
        if (confirmation) {
            try {
                const productRef = doc(db, "products", id);
                await deleteDoc(productRef);
                fetchProducts();
            } catch (error) {
                console.error("Error deleting product:", error);
            }
        }
    };

    // Handle modal open for adding or editing
    const openModal = (product = null) => {
        if (product) {
            setProductData(product); // Pre-fill form with product data for editing
            setEditProductId(product.id);
        } else {
            setProductData({
                category: '',
                details: '',
                imgUrl: [],
                price: '',
                productName: '',
                productType: '',
                returns: '',
                shipping: '',
                sizes: [],
            });
            setEditProductId(null);
        }
        setShowModal(true);
    };

    // Upload images to Firebase storage
    const handleImageUpload = async (e) => {
        const files = e.target.files;
        if (files) {
            setUploading(true);
            const uploadedImageUrls = [];
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const storageRef = ref(storage, `products/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);

                uploadTask.on('state_changed', 
                    (snapshot) => {
                        // Progress can be handled here
                    },
                    (error) => {
                        console.error("Error uploading image:", error);
                    },
                    async () => {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        uploadedImageUrls.push(downloadURL);

                        if (uploadedImageUrls.length === files.length) {
                            setProductData({
                                ...productData,
                                imgUrl: uploadedImageUrls,
                            });
                            setUploading(false);
                        }
                    }
                );
            }
        }
    };

    // Handle size checkbox selection
    const handleSizeChange = (e) => {
        const { value, checked } = e.target;
        setProductData(prevData => {
            const updatedSizes = checked 
                ? [...prevData.sizes, value] 
                : prevData.sizes.filter(size => size !== value);
            return { ...prevData, sizes: updatedSizes };
        });
    };

    // Fetch products when component mounts or when products change
    useEffect(() => {
        fetchProducts();
    }, []);

    if (loading) return <p>Loading products...</p>;

    return (
        <div className="manage-products">
            <h1>Manage Products</h1>
            <button onClick={() => openModal()}>Add Product</button>

            {/* Products Table */}
            <table className="products-table">
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Category</th>
                        <th>Price</th>
                        <th>Product Type</th>
                        <th>Sizes</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product) => (
                        <tr key={product.id}>
                            <td>{product.productName}</td>
                            <td>{product.category}</td>
                            <td>${product.price}</td>
                            <td>{product.productType}</td>
                            <td>{product.sizes.join(', ')}</td>
                            <td>
                                <button onClick={() => openModal(product)}>Update</button>
                                <button onClick={() => deleteProduct(product.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal for Add/Edit */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>{editProductId ? 'Edit Product' : 'Add Product'}</h2>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            if (editProductId) {
                                editProduct();
                            } else {
                                addProduct();
                            }
                        }}>
                            <div className="form-group">
                                <label>Product Name</label>
                                <input
                                    type="text"
                                    value={productData.productName}
                                    onChange={(e) => setProductData({ ...productData, productName: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Category</label>
                                <input
                                    type="text"
                                    value={productData.category}
                                    onChange={(e) => setProductData({ ...productData, category: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Details</label>
                                <input
                                    type="text"
                                    value={productData.details}
                                    onChange={(e) => setProductData({ ...productData, details: e.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                <label>Price</label>
                                <input
                                    type="number"
                                    value={productData.price}
                                    onChange={(e) => setProductData({ ...productData, price: parseFloat(e.target.value) })}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Product Type</label>
                                <input
                                    type="text"
                                    value={productData.productType}
                                    onChange={(e) => setProductData({ ...productData, productType: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Returns</label>
                                <textarea
                                    value={productData.returns}
                                    onChange={(e) => setProductData({ ...productData, returns: e.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                <label>Shipping</label>
                                <input
                                    type="text"
                                    value={productData.shipping}
                                    onChange={(e) => setProductData({ ...productData, shipping: e.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                <label>Sizes</label>
                                <div className="checkbox-group">
                                    {['small', 'medium', 'large'].map(size => (
                                        <label key={size}  className='size-check'>
                                            <input
                                                type="checkbox"
                                                value={size}
                                                checked={productData.sizes.includes(size)}
                                                onChange={handleSizeChange}
                                            />
                                            {size}
                                        </label>
                                    ))}
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Images</label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handleImageUpload}
                                />
                                {uploading && <p>Uploading images...</p>}
                            </div>
                            <button type="submit">{editProductId ? 'Update Product' : 'Add Product'}</button>
                            <button type="button" onClick={() => setShowModal(false)}>Cancel</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManageProducts;
