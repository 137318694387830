import { createSlice } from "@reduxjs/toolkit";

import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../firebase.config";

const initialState = {
    user: null,
    isAuthenticated: false,
    loading: false,
    error: null
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.userInfo = action.payload;
            state.isAuthenticated = true;
        },
        clearUser: (state) => {
            state.userInfo = null;
            state.isAuthenticated = false;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const { setUser, clearUser, setLoading, setError } = userSlice.actions;
export default userSlice.reducer;

export const fetchUserData = () => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const user = auth.currentUser;
        if (user) {
            const userDocRef = doc(db, 'users', user.uid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
                const { firstName, lastName, email, phoneNumber, address, points } = userDocSnap.data();
                dispatch(setUser({firstName, lastName, email, phoneNumber, address, points}));
            }
        }
    } catch (error) {
        dispatch(setError(error.message));
    } finally {
        dispatch(setLoading(false));
    }
};


export const logoutUser = () => (dispatch) => {
    auth.signOut().then(() => {
        dispatch(clearUser());
        localStorage.removeItem('user');
    }).catch((error) => {
        dispatch(setError(error.message));
    });
};
