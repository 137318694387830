import React, {useState} from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase.config';
import { toast } from 'react-toastify';
import Helmet from '../../components/Helmet/Helmet';

import '../../styles/forgotPassword.css';

const ForgortPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await sendPasswordResetEmail(auth, email);
            toast.success('Password reset email sent! Check your inbox.');
        } catch (error) {
            if (error.code === 'auth/user-not-found') {
                toast.error('No user found with this email address.');
            } else {
                toast.error('Failed to send password reset email. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Helmet title='Forgot Password'>
            <div className="forgot-password-container">
                <h2>Forgot Password</h2>
                <form onSubmit={handleResetPassword}>
                    <div className="form-group">
                        <label htmlFor="email">Email Address</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" disabled={loading}>
                        {loading ? 'Sending...' : 'Send Password Reset Email'}
                    </button>
                </form>
            </div>
        </Helmet>
    );
}

export default ForgortPassword;