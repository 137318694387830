
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    cartItems: [],
    totalAmount: 0,
    totalQuantity: 0
}

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addItem: (state, action) => {
            const newItem = action.payload;
            const existingItem = state.cartItems.find(
                item => item.id === newItem.id &&
                item.size === newItem.size);
            
            if (!existingItem || newItem.quantity) {
                state.totalQuantity += newItem.quantity || 1;
            }

            if (!existingItem) {
                state.cartItems.push({
                    id: newItem.id,
                    productName: newItem.productName,
                    imgUrl: newItem.imgUrl,
                    price: newItem.price,
                    size: newItem.size,
                    quantity: newItem.quantity || 1,
                    totalPrice: newItem.price
                })
            }

            else {
                existingItem.quantity += newItem.quantity || 1;
                existingItem.totalPrice = Number(existingItem.totalPrice) + Number(newItem.price);
            }

            state.totalAmount = Number(state.cartItems.reduce((total, item) => 
                total + Number(item.price) * Number(item.quantity), 0
            ));

            state.totalAmount = Math.round(state.totalAmount * 100) / 100
        },

        deleteItem: (state, action) => {
            const {id, size} = action.payload
            const existingItemIndex = state.cartItems.findIndex((item) => (item.id === id && item.size === size));

            if (existingItemIndex !== -1) {
                const existingItem = state.cartItems[existingItemIndex];
                state.cartItems.splice(existingItemIndex, 1); // Remove the item from the cart
                state.totalQuantity -= existingItem.quantity;

                // Recalculate total amount
                state.totalAmount = state.cartItems.reduce((total, item) => total + Number(item.price) * Number(item.quantity), 0);
            }
        },

        updateItemQuantity: (state, action) => {
            const { id, quantity } = action.payload;
            const existingItemIndex = state.cartItems.findIndex(item => item.id === id);

            if (existingItemIndex !== -1) {
                const existingItem = state.cartItems[existingItemIndex];
                const quantityDifference = quantity - existingItem.quantity;

                existingItem.quantity = quantity;
                state.totalAmount += Math.round((quantityDifference * Number(existingItem.price)) * 100) / 100;
                state.totalQuantity += quantityDifference;
            }
        }
    }
});

export const cartActions = cartSlice.actions

export default cartSlice.reducer