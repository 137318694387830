import React, {useState, useRef, useEffect, useLayoutEffect} from 'react';
import Draggable from 'react-draggable';

import Helmet from '../components/Helmet/Helmet';
import retinaBgVideo from '../assets/videos/retina-bg.mp4';

import '../styles/retina.css';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase.config';

import { useNavigate } from 'react-router-dom';

const Retina = () => {

    const [positions, setPositions] = useState([]);
    const [activeDrags, setactiveDrags] = useState(0);
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
    const [zIndices, setZIndices] = useState({});
    const containerRef = useRef(null);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getRandomPosition = (container) => {
        if (!container) {
            return { x: 0, y: 0 };
        }
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;
        const draggableWidth = 240; // Adjust this according to your draggable element
        const draggableHeight = 240; // Adjust this according to your draggable element

        const maxX = containerWidth - draggableWidth;
        const maxY = containerHeight - draggableHeight;

        const x = Math.floor(Math.random() * maxX);
        const y = Math.floor(Math.random() * maxY);

        return { x, y };
    }

    useEffect(() => {
       const fetchProducts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "products"));
                const productsList = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                const filteredProducts = productsList.filter(item => item.category === 'NFT');
                setData(filteredProducts);
            } catch (error) {
                console.error("Error fetching products: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    useLayoutEffect(() => {
        if (containerRef.current && data.length > 0) {
            const newPositions = data.map(() => getRandomPosition(containerRef.current));
            setPositions(newPositions);
        }
    }, [data]);

    const onStart = (e, productId) => {
        setactiveDrags(activeDrags => ++activeDrags);
        const clientX = e.clientX || e.touches[0].clientX;
        const clientY = e.clientY || e.touches[0].clientY;
        setStartPosition({ x: clientX, y: clientY });
        // Calculate the maximum zIndex
        const maxZIndex = Object.values(zIndices).reduce((max, val) => Math.max(max, val), 0);
        const nextZIndex = isFinite(maxZIndex) ? maxZIndex + 1 : 1;
        setZIndices(prev => ({
            ...prev,
            [productId]: nextZIndex
        }));
    };

    const onStop = (e, productId) => {
        setactiveDrags(activeDrags => --activeDrags);
        const clientX = e.clientX || e.changedTouches[0].clientX;
        const clientY = e.clientY || e.changedTouches[0].clientY;
        const endPosition = { x: clientX, y: clientY };
        const distance = Math.sqrt(
            Math.pow(endPosition.x - startPosition.x, 2) +
            Math.pow(endPosition.y - startPosition.y, 2)
        );

        // If the distance is less than a threshold, consider it a click
        if (distance < 5) {
            navigate(`/productDetails/${productId}`);
        }
    };

    return <Helmet title={'Retina+'}>
        <section className='retina'>
            <div className='content-container'>
                <div className="video-background-container">
                    <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        className="video-background"
                    >
                        <source src={retinaBgVideo} type="video/mp4" />
                    </video>
                </div>
                <div className="retina-container" ref={containerRef}>
                    {data.map((product, index) => (
                        <Draggable
                            key={product.id}
                            onStart={(e) => onStart(e, product.id)}
                            onStop={(e) => onStop(e, product.id)}
                        >
                            <div 
                                role='button' 
                                className="draggable-product"  
                                style={{ 
                                    position: 'absolute', 
                                    left: positions[index]?.x, 
                                    top: positions[index]?.y,
                                    zIndex: zIndices[product.id] || 1
                                }} 
                            >
                                <video
                                    src={product.vidUrl}
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    width="100%"
                                    height="auto"
                                />
                            </div>
                        </Draggable>
                    ))}
                </div>
            </div>

        </section>
    </Helmet>
}

export default Retina