import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, limit, startAfter, orderBy, endBefore, getCountFromServer } from 'firebase/firestore';
import { db } from '../../../firebase.config';
import '../../../styles/manageUsers.css';

const ManageUsers = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lastVisible, setLastVisible] = useState(null); // For pagination
    const [firstVisible, setFirstVisible] = useState(null); // For previous page pagination
    const [sortField, setSortField] = useState('firstName'); // Default sort field
    const [sortOrder, setSortOrder] = useState('asc'); // Default sort order
    const [totalUsers, setTotalUsers] = useState(0); // Total users in the database
    const [disableNext, setDisableNext] = useState(false);
    const [disablePrev, setDisablePrev] = useState(true); // Initially, disable "Previous" button
    const [currentPage, setCurrentPage] = useState(1); // Track current page
    const [usersPerPage, setUsersPerPage] = useState(10); // Track the number of users per page
    const [tempUsersPerPage, setTempUsersPerPage] = useState(10); // Temporary state to hold user input for users per page

    // Function to format Firestore timestamp
    const formatDate = (timestamp) => {
        if (timestamp && timestamp.seconds) {
            return new Date(timestamp.seconds * 1000).toLocaleString(); // Customize the format as needed
        }
        return 'N/A'; // Default value if no timestamp
    };

    // Fetch the total number of users to calculate total pages
    const fetchTotalUsers = async () => {
        try {
            const usersCollection = collection(db, "users");
            const snapshot = await getCountFromServer(usersCollection); // Get the total count of users
            setTotalUsers(snapshot.data().count); // Update the state with the total count
        } catch (error) {
            console.error("Error fetching total users:", error);
        }
    };

    // Fetch users from Firestore with sorting and pagination
    const fetchUsers = async (sortField = 'firstName', sortOrder = 'asc', lastVisible = null, firstVisible = null) => {
        try {
            setLoading(true); // Ensure loading is true when fetching users

            // Start with the base query
            let userQuery = query(
                collection(db, "users"),
                orderBy(sortField, sortOrder), // Apply sorting by the selected field and order
                limit(usersPerPage)
            );

            // Apply pagination if applicable
            if (lastVisible) {
                userQuery = query(userQuery, startAfter(lastVisible)); // Pagination for next page
            }
            if (firstVisible) {
                userQuery = query(userQuery, endBefore(firstVisible)); // Pagination for previous page
            }

            console.log("Fetching users with query:", userQuery);

            const querySnapshot = await getDocs(userQuery);

            if (querySnapshot.empty) {
                console.log("No users found.");
            } else {
                const fetchedUsers = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                console.log("Fetched Users:", fetchedUsers);

                setUsers(fetchedUsers); // Update state with fetched users
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]); // For pagination (next page)
                setFirstVisible(querySnapshot.docs[0]); // For pagination (previous page)
                setDisableNext(querySnapshot.docs.length < usersPerPage); // Disable next if it's the last page
                setDisablePrev(currentPage === 1); // Disable previous if we are on the first page
            }
        } catch (error) {
            console.error("Error fetching users:", error);
        } finally {
            setLoading(false); // Ensure loading is set to false even if an error occurs
        }
    };

    // Handle Sort Button Click
    const handleSort = (field) => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(newSortOrder);
        setCurrentPage(1); // Reset to page 1 when sorting
    };

    // Handle Next Page
    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
        fetchUsers(sortField, sortOrder, lastVisible);
    };

    // Handle Previous Page
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
            fetchUsers(sortField, sortOrder, null, firstVisible);
        }
    };

    // Handle users per page input change (update temporary state)
    const handleUsersPerPageInput = (e) => {
        const value = Math.max(1, parseInt(e.target.value, 10)); // Ensure it's a positive number
        setTempUsersPerPage(value); // Store the value temporarily while typing
    };

    // Apply the users per page change when the input loses focus
    const handleUsersPerPageBlur = () => {
        setUsersPerPage(tempUsersPerPage); // Apply the change only when the user leaves the input field
        setCurrentPage(1); // Reset to page 1 when changing users per page
    };

    // Fetch users initially and whenever sortField, sortOrder, or usersPerPage changes
    useEffect(() => {
        console.log("Fetching users...");
        fetchUsers(sortField, sortOrder); // Fetch initial users on component mount
        fetchTotalUsers(); // Fetch total number of users to calculate total pages
    }, [sortField, sortOrder, usersPerPage]);

    useEffect(() => {
        setDisablePrev(currentPage === 1); // Always update disablePrev based on currentPage
    }, [currentPage]);

    if (loading) return <p>Loading users...</p>;

    // Calculate total pages
    const totalPages = Math.ceil(totalUsers / usersPerPage);

    return (
        <div className="manage-users">
            <h1>Manage Users</h1>
            <div className="controls">
                <button onClick={() => handleSort('firstName')}>Sort by First Name ({sortOrder})</button>
                <button onClick={() => handleSort('email')}>Sort by Email ({sortOrder})</button>
                <button onClick={() => handleSort('createdAt')}>Sort by Date Created ({sortOrder})</button>
                <div className="users-per-page">
                    <label htmlFor="users-per-page">Users per page:</label>
                    <input
                        id="users-per-page"
                        type="number"
                        value={tempUsersPerPage} // Bind to temporary state for immediate feedback
                        onChange={handleUsersPerPageInput} // Update tempUsersPerPage as the user types
                        onBlur={handleUsersPerPageBlur} // Apply the change only when the input loses focus
                        min="1"
                        placeholder="e.g. 10"
                        style={{ marginLeft: "10px", padding: "5px", width: "80px" }}
                    />
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Points</th>
                        <th>Date Created</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.firstName || 'N/A'}</td>
                            <td>{user.lastName || 'N/A'}</td>
                            <td>{user.email || 'N/A'}</td>
                            <td>{user.phoneNumber || 'N/A'}</td>
                            <td>{user.points || 'N/A'}</td>
                            <td>{formatDate(user.createdAt)}</td> {/* Use the formatted date */}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination-controls">
                <button onClick={handlePrevPage} disabled={disablePrev}>
                    Previous Page
                </button>
                <span>Page {currentPage} of {totalPages}</span> {/* Display current page of total pages */}
                <button onClick={handleNextPage} disabled={disableNext}>
                    Next Page
                </button>
            </div>
        </div>
    );
};

export default ManageUsers;
