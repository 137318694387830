import React, { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import '../../styles/dashboard.css';
import logo from '../../assets/images/thevisionlogowhite.png';
import { useNavigate } from 'react-router-dom';
import {toast} from 'react-toastify';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../redux/slices/userSlice';  

const Dashboard = () => {
    const [sidebarWidth, setSidebarWidth] = useState(250); // Default sidebar width

    const navigate = useNavigate();
    const dispatch = useDispatch();


    // Function to handle sidebar resizing
    const handleResize = (e) => {
        const newWidth = e.clientX;
        if (newWidth > 150 && newWidth < 400) {
            setSidebarWidth(newWidth);
        }
    };

    const handleLogout = async () => {
        try {
            await dispatch(logoutUser());
        } catch (error) {
            return;
        } finally {
            toast.success("Logged out successfully");
            navigate('/login')
        }
    };

    return (
        <div className="dashboard">
            {/* Resizable Sidebar */}
            <div
                className="dashboard-sidebar"
                style={{ width: `${sidebarWidth}px` }}
            >
                {/* Logo */}
                <div className="sidebar-logo">
                    <img src={logo} alt="Logo" className="logo-img" />
                </div>

                {/* Navigation Menu */}
                <nav className="dashboard-nav">
                    <NavLink to="/admin-dashboard/manage-users" activeClassName="active">
                        Manage Users
                    </NavLink>
                    <NavLink to="/admin-dashboard/manage-products" activeClassName="active">
                        Manage Products
                    </NavLink>
                    <NavLink to="/admin-dashboard/manage-orders" activeClassName="active">
                        Manage Orders
                    </NavLink>
                    <NavLink to="/admin-dashboard/payments" activeClassName="active">
                        Payments
                    </NavLink>
                </nav>

                {/* Logout Button */}
                <button className="logout-btn" onClick={handleLogout}>
                    Logout
                </button>
            </div>

            {/* Resizer */}
            <div
                className="dashboard-resizer"
                onMouseDown={(e) => {
                    document.addEventListener('mousemove', handleResize);
                    document.addEventListener('mouseup', () => {
                        document.removeEventListener('mousemove', handleResize);
                    });
                }}
            ></div>

            {/* Main Content */}
            <div className="dashboard-content">
                <Outlet /> {/* Render the respective pages */}
            </div>
        </div>
    );
};

export default Dashboard;
