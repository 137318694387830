import React from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import './contactForm.css';

const ContactForm = () => {
    const formik = useFormik({
        initialValues: {
          name: '',
          email: '',
          message: '',
          termsAndConditions: false
        },
        validationSchema: Yup.object({
          name: Yup.string()
            .max(15, 'Must be 15 characters or less')
            .required('Required'),
          email: Yup.string().email('Invalid email address').required('Required'),
          termsAndConditions: Yup.bool().oneOf([true], 'You need to accept the terms and conditions'),
        }),
        onSubmit: values => {
          alert(JSON.stringify(values, null, 2));
        },
    });
    
    return (
    <div className='form'>
        <form onSubmit={formik.handleSubmit}>
            <div className='input-block'>
                <label htmlFor="name" className='form-label'>Name*</label>
                <input
                    className='form-input'
                    id="name"
                    type="text"
                    {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name ? (
                    <div>{formik.errors.name}</div>
                ) : null}
            </div>
        
            <div className='input-block'>
                <label className='form-label' htmlFor="email">Email*</label>
                <input className='form-input' id="email" type="email" {...formik.getFieldProps('email')} />
                {formik.touched.email && formik.errors.email ? (
                    <div>{formik.errors.email}</div>
                ) : null}
            </div>

            <div className='input-block'>
            <label className='form-label' htmlFor="message">Message</label>
            <textarea className='text-area' placeholder='Type your message...' id="message" type="text" {...formik.getFieldProps('message')} />
            {formik.touched.message && formik.errors.message ? (
                <div>{formik.errors.message}</div>
            ) : null}
            </div>

            <div className='input-block'>
            <label>
              <input type='checkbox' name="termsAndConditions" />
              <span> I accept the <span>terms</span></span>
            </label>
            {formik.touched.termsAndConditions && formik.errors.termsAndConditions ? (
                <div>{formik.errors.termsAndConditions}</div>
            ) : null}
            </div>

        
            <button className='form-btn' type="submit">Submit</button>
        </form>
    </div>
  )
}

export default ContactForm