import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth, db } from '../firebase.config';
import { doc, getDoc } from 'firebase/firestore';
import { useDispatch } from 'react-redux';
import { setUser } from '../redux/slices/userSlice';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                console.log("User UID:", user.uid);  // Log UID when the user is authenticated

                try {
                    const userDocRef = doc(db, "users", user.uid);
                    const userDocSnap = await getDoc(userDocRef);

                    if (userDocSnap.exists()) {
                        const userDocData = userDocSnap.data();
                        const userData = {
                            uid: user.uid,
                            email: userDocData.email || '',
                            firstName: userDocData.firstName || '',
                            lastName: userDocData.lastName || '',
                            phoneNumber: userDocData.phoneNumber || '',
                            points: userDocData.points || 0,
                        };

                        setCurrentUser(userData);
                        dispatch(setUser(userData));
                        localStorage.setItem('user', JSON.stringify(userData));
                    } else {
                        console.error("User document does not exist in Firestore.");
                        setCurrentUser(null);
                    }
                } catch (error) {
                    console.error("Error getting user document:", error);
                    setCurrentUser(null);
                }
            } else {
                setCurrentUser(null);
                dispatch(setUser(null));
                localStorage.removeItem('user');
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, [dispatch]);
    
    const signIn = async (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    };

     const signOutUser = async () => {
        setLoading(true);
        try {
            await signOut(auth);
        } catch (error) {
        } finally {
            localStorage.removeItem('user');
            setLoading(false);
        }
    };
    
    const value = {
        currentUser,
        signIn,
        signOutUser,
        loading
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
