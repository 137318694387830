import React, {useRef, useState} from 'react';

import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import './header.css'; 

import CartModal from '../Modals/CartModal';

import { motion } from 'framer-motion'; 

import logo from '../../assets/images/the_vision_logo.jpeg';
import whiteLogo from '../../assets/images/lordwhite.jpeg';

import { Container, Row } from 'reactstrap';
import { useSelector } from 'react-redux';

const nav_links = [
    {
      path:'home',
      display:'Home'
    },

    {
      path:'grotto',
      display:'The Grotto'
    },

    {
      path:'retina',
      display:'Retina+'
    },

    {
      path:'brotherhood',
      display:'Brotherhood'
    },

    {
      path:'faq',
      display:'FAQ'
    },

    {
      path:'contact',
      display:'Contact Us'
    }
]

const Header = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const headerRef = useRef(null);
  const totalQuantity = useSelector(state => state.cart.totalQuantity);
  const menuRef = useRef(null);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const menuToggle = () => menuRef.current.classList.toggle('active-menu');

  const isGrottoActive = location.pathname.includes('grotto');

  return <header className={`header ${isGrottoActive ? 'grotto-active' : ''}`} ref={headerRef}>    
      <Container className='bg-container'>
          <Row>
              <div className='nav-wrapper'>
                  <div className='logo'>
                      <Link to='/home'><img src={isGrottoActive ? whiteLogo : logo} alt='logo'/></Link>
                  </div>

                  <div className='navigation' ref={menuRef} onClick={menuToggle}>
                      <ul className='menu'>
                        {
                          nav_links.map((item, index) => (
                            <li className={`nav-item ${isGrottoActive ? 'grotto-active' : ''}`} key={index}>
                                <NavLink to={item.path} className={(navClass) => navClass.isActive ? 'nav-active' : ''}>{item.display}</NavLink>
                            </li>
                          ))
                        }
                      </ul>
                  </div>

                  <div className={`nav-icons ${isGrottoActive ? 'grotto-active' : ''}`}>
                      <span className={`cart ${isGrottoActive ? 'grotto-active' : ''}`} onClick={toggle}>
                          <span className='cart-icon'>
                              <i class="ri-shopping-cart-line"></i>
                          </span>
                          <span className={`badge ${isGrottoActive ? 'grotto-active' : ''}`} >{totalQuantity}</span>
                      </span>

                      <div className='profile nav-item'>
                          <NavLink to='/profile' className={(navClass) => navClass.isActive ? 'nav-active' : ''}><motion.i whileTap={{scale: 1.2}} onClick='' class={`ri-user-line ${isGrottoActive ? 'grotto-active' : ''}`}></motion.i></NavLink>
                      </div>
                      <div className='mobile-menu'>
                          <span onClick={menuToggle}>
                              <i class="ri-menu-line"></i>
                          </span>
                      </div>
                  </div>

                  <CartModal isOpen={modal} toggle={toggle} />
              </div>
          </Row>
      </Container>
    
  </header>
  
}

export default Header