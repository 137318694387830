import { useEffect } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase.config';
import './App.css';
import Layout from './components/Layout/Layout';

function App() {
    useEffect(() => {
    // Call the Firebase Function to check or seed the super admin, only once on load
      const seedSuperAdmin = httpsCallable(functions, 'seedSuperAdmin');

      seedSuperAdmin()
        .then((result) => {
          console.log(result.data.message);
        })
        .catch((error) => {
          console.error('Error seeding super admin:', error);
        });
    }, []);
    return <Layout />;
}

export default App;
