import React, { useState } from 'react';
import Helmet from '../../components/Helmet/Helmet'; 
import { Container, Row, Col, Form, FormGroup, Button } from 'reactstrap';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MoonLoader } from 'react-spinners';
import img from '../../assets/images/login-image.png';

import { toast } from 'react-toastify';

import '../../styles/login.css';
import { setUser, setLoading, setError } from '../../redux/slices/userSlice';
import { useAuth } from '../../custom-hooks/useAuth';
import { auth, db } from '../../firebase.config';

import { doc, updateDoc, getDoc } from 'firebase/firestore';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const loading = useSelector((state) => state.user.loading);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { signIn } = useAuth();

    const signin = async (e) => {
        e.preventDefault();
        dispatch(setLoading(true));

        try {
            await signIn(email, password);
            const from = location.state?.from?.pathname || '/brotherhood';

            // Fetch the user document from Firestore
            const user = auth.currentUser;
            if (user) {
                const userDocRef = doc(db, "users", user.uid);
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();

                    // Check if it's the user's first login
                    if (userData.isFirstLogin === false) {
                        await updateDoc(userDocRef, {
                            isFirstLogin: true,
                        });
                        toast.success(`Congratulations! You have ${userData.points || 0} points for signing up.`);
                    }
                
                    // Check if the user is a super admin
                    const idTokenResult = await user.getIdTokenResult();
                    const isSuperAdmin = idTokenResult.claims.isSuperAdmin;

                    if (isSuperAdmin) {
                        // Redirect to the admin dashboard if the user is a super admin
                        navigate('/admin-dashboard', { replace: true });
                        toast.success('Admin Login successful!');
                    } else {
                        // Only store fields that are actually present in the document
                        const userDetails = {};
                        if (userData.firstName) userDetails.firstName = userData.firstName;
                        if (userData.lastName) userDetails.lastName = userData.lastName;
                        if (userData.email) userDetails.email = userData.email;
                        if (userData.phoneNumber) userDetails.phoneNumber = userData.phoneNumber;
                        if (userData.points) userDetails.points = userData.points;

                        localStorage.setItem('user', JSON.stringify(userDetails));
                        dispatch(setUser(userDetails));

                        navigate(from, { replace: true });
                        toast.success('Login successful!');
                    }
                }   
            } else {
                toast.error('User document not found.');
            }
            
        } catch (error) {
            console.error("Sign-in error:", error.message);
            if (error.code === 'auth/wrong-password') {
                alert("Incorrect password. Please try again.");
            } else if (error.code === 'auth/user-not-found') {
                alert("No user found with this email.");
            } else if (error.code === 'auth/invalid-api-key') {
                alert("Invalid Firebase API key. Please check your configuration.");
            } else {
                alert("Sign-in failed. Please try again.");
            }
            throw error; // Optionally rethrow to handle it in a component
            dispatch(setLoading(false));
            dispatch(setError(error.message));
            toast.error(error.message);
        } finally {
            dispatch(setLoading(false));
        }
    }
    return (
        <Helmet title="Login">
            <section>
                <Container>
                    <Row>
                        <Col lg="6" className='login-form'>
                            <h3>LOGIN.</h3>

                            <Form className='auth-form' onSubmit={signin}>
                                <FormGroup className='login-input'>
                                    <input 
                                            type='email' 
                                            placeholder='Email'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)} 
                                        />
                                </FormGroup>
                                <FormGroup className='login-input'>
                                        <input 
                                            type='password' 
                                            placeholder='Password'
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)} 
                                        />
                                </FormGroup>
                                
                                <div className='auth_btn-wrapper'>
                                    <Button className='btn-login' title='Sign in'>
                                        {loading 
                                            ? (<MoonLoader color="#fff" size={20}  />)
                                            : ("Sign in")
                                        }              
                                    </Button>
                                    <p><Link to='/forgotPassword' className='forgot-password'>Forgot your password?</Link></p>
                                </div>
                                <p className='signup'>Not a member? <Link to='/signup' className='signup-link'>Sign up now</Link></p>
                            </Form>
                            
                            <p>"In the realm of creativity, where imagination knows no bounds, we celebrate: Welcome back to the brotherhood”</p>
                            <img src={img} alt='man-chilling' />
                        </Col>
                    </Row>
                </Container>
            </section>
        </Helmet>
    )
}

export default Login