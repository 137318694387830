import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Button } from 'reactstrap';
import Helmet from '../components/Helmet/Helmet';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { auth, db } from '../firebase.config';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import '../styles/checkout.css';
import { ShippingStatus } from '../components/Helpers/Enums/ShippingStatus';
import CalculateShippingRate from '../components/Helpers/CalculateShippingRate';
import { toast } from 'react-toastify';
import { functions } from '../firebase.config';
import { httpsCallable } from "firebase/functions"
import 'firebase/functions';
// import firebase from 'firebase/compat/app';

// Load Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPEPUBLISHABLEKEY);

const CheckoutForm = ({ shippingCharges, setShippingCharges, shippingAddress, setShippingAddress }) => {
    const [email, setEmail] = useState('');
    const [billingAddress, setBillingAddress] = useState({
        fullName: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        country: 'United States'
    });
    const [isSameAddress, setIsSameAddress] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isAddressValid, setIsAddressValid] = useState(false);

    const getUSPSAccessToken = httpsCallable(functions, 'getAccessToken');


    const handleShippingChange = (e) => {
        const { name, value } = e.target;
        setShippingAddress((prev) => ({
            ...prev,
            [name]: value
        }));

        if (isSameAddress) {
            setBillingAddress((prev) => ({
                ...prev,
                [name]: value
            }));
        }

        setIsAddressValid(false);
        setShippingCharges(0);
    };

    const handleBillingChange = (e) => {
        const { name, value } = e.target;
        setBillingAddress((prev) => ({
            ...prev,
            [name]: value
        }));
    };
    const handleCheckboxChange = (e) => {
        setIsSameAddress(e.target.checked);
        if (e.target.checked) {
            setBillingAddress(shippingAddress);
        }
    };

    const fetchShippingPrice = async(shippingAddress, packageData) =>  {
        try {
            // Get USPS access token
            const tokenResponse = await getUSPSAccessToken();
            const accessToken = tokenResponse.data.accessToken;

            // Now you can use the access token to make requests to the USPS API
            const validateAddress = httpsCallable(functions, 'validateAddress');
            const calculateShippingPrice = httpsCallable(functions, 'calculateShippingPrice');

            // Validate the address first
            const validatedAddress = await validateAddress({
                accessToken: accessToken,
                streetAddress: shippingAddress.street,
                city: shippingAddress.city,
                state: shippingAddress.state,
                zipCode: shippingAddress.zipCode
            });

            // Now calculate shipping cost using validated address
            const shippingCost = await calculateShippingPrice({
                accessToken: accessToken,
                originZip: packageData.originZip,
                destinationZip: validatedAddress.data.standardizedAddress.zipCode,
                weightInOunces: packageData.weightInOunces,
                containerType: packageData.containerType,
                size: packageData.size,
                serviceType: packageData.serviceType
            });

            console.log('Shipping cost:', shippingCost.data.shippingPrice);
        } catch (error) {
            console.error('Error fetching shipping price:', error);
        }
    }

    const ValidateAddress = async () => {
        const functionUrl = 'https://validateaddress-tyiughjr4a-uc.a.run.app'; // Update this URL

        try {
            const response = await fetch(functionUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    address: {
                        address1: shippingAddress.street,
                        address2: '', // If you have an address line 2
                        city: shippingAddress.city,
                        state: shippingAddress.state,
                        zip5: shippingAddress.zip,
                        zip4: '', // Optional
                    },
                }),
            });

            const result = await response.json();
            console.log(result)
            if (result.Error) {
                throw new Error(result.Error.Description);
            }
            // Handle the validated address here (update form, show confirmation, etc.)
            // If validation is successful, set address as valid
            setIsAddressValid(true);
            setError(null); // Clear any previous errors
        } catch (error) {
            console.error("Address Validation Error:", error);
            setError("Invalid shipping address. Please check your details.");
            setIsAddressValid(false); // Address is not valid
        }
    };

    const subTotal = useSelector(state => state.cart.totalAmount);
    //const shippingCharges = 20.00;
    const total = Math.round((subTotal + shippingCharges) * 100) / 100;

    const functionUrl = 'https://createpaymentintent-tyiughjr4a-uc.a.run.app'

    const stripe = useStripe();
    const elements = useElements();

    const getCurrentUserId = () => {
        const user = auth.currentUser;
        setEmail(user.email)
        return user ? user.uid : null;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        await ValidateAddress()

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        try {
            const response = await fetch(functionUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    amount: total * 100, // amount in cents
                    currency: 'usd',
                }),
            });

            const { clientSecret } = await response.json();

            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: billingAddress.fullName,
                        email: email,
                        address: {
                            line1: billingAddress.street,
                            city: billingAddress.street,
                            state: billingAddress.state,
                            postal_code: billingAddress.zip,
                            country: 'US',
                        },
                    },
                },
            });

            if (result.error) {
                console.error(result.error.message);
            } else if (result.paymentIntent.status === 'succeeded') {
                alert('Payment Successful!');
                // Store additional information in Firestore
                const userId = getCurrentUserId();
                const purchaseRef = collection(db, "purchases");
                await addDoc(purchaseRef, { 
                    id: purchaseRef.id,
                    userId: userId,
                    shippingAddress: shippingAddress,
                    pointsUsed: 0,
                    shippingFee: shippingCharges,
                    shippingStatus: ShippingStatus.PENDING,
                    createdAt: new Date(),
                    updatedAt: null
                });
            }
        } catch (error) {
            console.error("Payment Error:", error);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <div className='form-section'>
                <span className='form-subtitle'>
                    <h6>Customer Info</h6>
                    <p>* Required</p>
                </span>
                <FormGroup className='form-group'>
                    <label>Email *</label>
                    <input
                        type='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </FormGroup>
            </div>

            <div className='form-section'>
                <span className='form-subtitle'>
                    <h6>Shipping Address</h6>
                    <p>* Required</p>
                </span>
                <FormGroup className='form-group'>
                    <label>Full Name *</label>
                    <input
                        name='fullName'
                        type='text'
                        value={shippingAddress.fullName}
                        onChange={handleShippingChange}
                        required
                    />
                </FormGroup>
                <FormGroup className='form-group'>
                    <label>Street Address *</label>
                    <input
                        name='street'
                        type='text'
                        value={shippingAddress.street}
                        onChange={handleShippingChange}
                        required
                    />
                </FormGroup>
                <Row>
                    <Col md={4}>
                        <FormGroup className='form-group'>
                            <label>City *</label>
                            <input
                                name='city'
                                type='text'
                                value={shippingAddress.city}
                                onChange={handleShippingChange}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup className='form-group'>
                            <label>State/Province</label>
                            <input
                                name='state'
                                type='text'
                                value={shippingAddress.state}
                                onChange={handleShippingChange}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup className='form-group'>
                            <label>Zip/Postal Code *</label>
                            <input
                                name='zip'
                                type='text'
                                value={shippingAddress.zip}
                                onChange={handleShippingChange}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup className='form-group'>
                    <label>Country *</label>
                    <input
                        name='country'
                        type='text'
                        value={shippingAddress.country}
                        readOnly
                    />
                </FormGroup>

                <Button type="button" onClick={fetchShippingPrice} disabled={loading || isAddressValid}>
                    {loading ? 'Calculating...' : 'Calculate Shipping Fee'}
                </Button>
            </div>

            <div className='form-section'>
                <span className='form-subtitle'>
                    <h6>Shipping Method</h6>
                </span>
                <FormGroup className='form-group'>
                    <div className='radio-input'>
                        <input
                            type='radio'
                            role='button'
                            checked
                            readOnly
                        />
                        <label>Shipping Charges</label>
                    </div>
                </FormGroup>
            </div>

            <div className='form-section'>
                <span className='form-subtitle'>
                    <h6>Payment Info</h6>
                    <p>* Required</p>
                </span>
                <FormGroup className='form-group'>
                    <label>Card Number *</label>
                    <CardElement />
                </FormGroup>
                <FormGroup className='form-group'>
                    <div className='radio-input'>
                        <input
                            type='checkbox'
                            role='button'
                            checked={isSameAddress}
                            onChange={handleCheckboxChange}
                        />
                        <label>Billing address same as shipping</label>
                    </div>
                </FormGroup>
                <button onClick={fetchShippingPrice}>GetPrice</button>
            </div>

            {!isSameAddress &&
                <div className='form-section billing-address'>
                    <span className='form-subtitle'>
                        <h6>Billing Address</h6>
                        <p>* Required</p>
                    </span>
                    <FormGroup className='form-group'>
                        <label>Full Name *</label>
                        <input
                            name='fullName'
                            type='text'
                            value={billingAddress.fullName}
                            onChange={handleBillingChange}
                            required
                        />
                    </FormGroup>
                    <FormGroup className='form-group'>
                        <label>Street Address *</label>
                        <input
                            name='street'
                            type='text'
                            value={billingAddress.street}
                            onChange={handleBillingChange}
                            required
                        />
                    </FormGroup>
                    <Row>
                        <Col md={4}>
                            <FormGroup className='form-group'>
                                <label>City *</label>
                                <input
                                    name='city'
                                    type='text'
                                    value={billingAddress.city}
                                    onChange={handleBillingChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className='form-group'>
                                <label>State/Province</label>
                                <input
                                    name='state'
                                    type='text'
                                    value={billingAddress.state}
                                    onChange={handleBillingChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className='form-group'>
                                <label>Zip/Postal Code *</label>
                                <input
                                    name='zip'
                                    type='text'
                                    value={billingAddress.zip}
                                    onChange={handleBillingChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup className='form-group'>
                        <label>Country *</label>
                        <input
                            name='country'
                            type='text'
                            value={billingAddress.country}
                            readOnly
                        />
                    </FormGroup>
                </div>
            }

            <Button type="submit" disabled={loading}>
                {loading ? 'Processing...' : 'Place Order'}
            </Button>
            {error && <div>{error}</div>}
        </Form>
    );
};

const Checkout = () => {
    const [shippingCharges, setShippingCharges] = useState(20);
    const [shippingAddress, setShippingAddress] = useState({
        fullName: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        country: 'United States'
    });
    const subTotal = useSelector(state => state.cart.totalAmount);
    const total = Math.round((subTotal + shippingCharges) * 100) / 100;

    return (
        <Helmet title="Checkout">
            <section className='bg-color checkout'>
                <Container>
                    <Row>
                        <Col md={6} sm={12} className='form-grid'>
                            <div className='form-col float-end'>
                                <Elements stripe={stripePromise}>
                                    <CheckoutForm 
                                        setShippingCharges={setShippingCharges}
                                        shippingCharges={shippingCharges}
                                        shippingAddress={shippingAddress}
                                        setShippingAddress={setShippingAddress}
                                    />
                                </Elements>
                            </div>
                        </Col>
                        <Col md={3} sm={12} className='order-summary'>
                            <div className='form-section'>
                                <span className='form-subtitle'>
                                    <h6>Order Summary</h6>
                                </span>
                                <span className='fee-details'>
                                    <span className='price'>
                                        <p>Subtotal</p>
                                        <p>$ {subTotal.toFixed(2)} USD</p>
                                    </span>
                                    <span className='price'>
                                        <p>Shipping Charges</p>
                                        <p>$ {shippingCharges.toFixed(2)} USD</p>
                                    </span>
                                    <span className='price'>
                                        <p>Total</p>
                                        <p>$ {total.toFixed(2)} USD</p>
                                    </span>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Helmet>
    );
};

export default Checkout;
