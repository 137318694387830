import React from 'react';

import Helmet from '../components/Helmet/Helmet';

import CustomAccordion from '../components/Accordion/CustomAccordion';
import faqData from '../assets/data/faqData';
import '../styles/faq.css';
import { Container, Row } from 'reactstrap';

const FAQ = () => {
    return (
        <Helmet title={'FAQ'}>
                <Container>
                    <Row className="faq-container">
                        <div className="faq-content">
                            <h1 className="faq-title">FAQ</h1>
                            <div className="accordion">
                                <CustomAccordion accordionData={faqData} />
                            </div>
                        </div>
                    </Row>
                    <Row className="gap" />
                </Container>
        </Helmet>
    );
};

export default FAQ;
