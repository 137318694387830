import React from 'react'
import Helmet from '../components/Helmet/Helmet'
import { Col, Container, Row } from 'reactstrap'
import heroimg from '../assets/images/member-hero.jpeg';
import tier1 from '../assets/images/tier1-image.png';
import tier2 from '../assets/images/tier2-image.png';
import tier3 from '../assets/images/tier3-image.png';
import tier4 from '../assets/images/tier4-image.png';
import { Link, useNavigate } from 'react-router-dom';
import {toast} from 'react-toastify';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../redux/slices/userSlice';

import '../styles/brotherhood.css';
import { useAuth } from '../custom-hooks/useAuth';

const Brotherhood = () => {
    const { currentUser, signOutUser, loading } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = async () => {
        try {
            await dispatch(logoutUser());
        } catch (error) {
            return;
        } finally {
            toast.success("Logged out successfully");
            navigate('/login')
        }
    };


    return <Helmet title='Brotherhood'>
        <div className='brotherhood'>
            <section className='member_hero-bg'>
                <Row>
                    <Col lg={6} md={12} sm={12} className='hero-bg'>
                        <div className='hero-image'>
                            <img src={heroimg} alt='heroimg'  />
                        </div>
                    </Col>

                    <Col lg={6} md={12} sm={12} className='center-col'>
                        <div className='hero-details'>
                            <h2 className='hero-text'>The Brotherhood Rewards</h2>
                            
                            <p className='hero-text'>
                                Upon becoming apart of the brotherhood, you’ll save on shipping, products
                                and digital artwork all whilst turning points into rewards. get 10 points instantly when you join.
                            </p>
                            {currentUser ? (
                            <div className='btn-wrapper'>
                                <Link className='btn-cra' to='/profile'>My Profile</Link>
                                <button className='btn-log' onClick={handleLogout}>Logout</button>
                            </div>
                            ) : (
                            <div className='btn-wrapper'>
                                <Link className='btn-cra' to='/signup'>Create an Account</Link>
                                <Link className='btn-log' to='/login'>Login</Link>
                            </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </section>

            <section className='member_section'>
                <Container>
                    <Row className='work-row'>
                        <h2 className='works-title'>How it works</h2>
                        <div className='work-items'>
                            <Col lg='4' md='8' sm='12'>
                                <div className='work-item'>
                                    <button className='btn-cta'>JOIN</button>
                                    <p className='text-center'>Create a free account and immediately get 10 points and tier 1 rewards.</p>
                                </div>
                            </Col>

                            <Col lg='4' md='8' sm='12'>
                                <div className='work-item'>
                                    <button className='btn-cta'>EARN</button>
                                    <p className='text-center'>Earn 1 point for every $1 you spend on <link title='lordvisiongarments.com' /> or follow the list of alternative ways to earn points.</p>
                                </div>
                            </Col>

                            <Col lg='4' md='8' sm='12'>
                                <div className='work-item'>
                                    <button className='btn-cta'>REWARDS</button>
                                    <p className='text-center'>Redeem your points for discounts and transcend the tiers for larger rewards and  exclusive access to content.</p>
                                </div>
                            </Col>
                        </div>
                    </Row>
                </Container>
            </section>

            <section className='member_section'>
                <Container>
                    <Row className='tier-row'>
                        <Col md={3} className='work-block'>
                            <div className='work-item'>
                                <img src={tier1} className='tier-image' alt=''/>
                                <p className='benefits-heading'>Tier 1: Acolyte free entry</p>

                                <p className='benefits-text'>10 free points</p>
                                <p className='benefits-text'>10% off first purchase</p>
                                <p className='benefits-text'>10% off all digital artwork permanently</p>
                                
                            </div>
                        </Col>

                        <Col md={3} className='work-block'>
                            <div className='work-item'>
                                <img src={tier2} className='tier-image' alt=''/>
                                <p className='benefits-heading'>Tier 2: Disciple 100 points</p>

                                <p className='benefits-text'>20 free points</p>
                                <p className='benefits-text'>20% off first purchase</p>
                                <p className='benefits-text'>20% off all digital artwork permanently</p>
                                
                            </div>
                        </Col>

                        <Col md={3} className='work-block'>
                            <div className='work-item'>
                                <img src={tier3} className='tier-image' alt=''/>
                                <p className='benefits-heading'>Tier 3: Soverign 500 points</p>

                                <p className='benefits-text'>30 free points</p>
                                <p className='benefits-text'>10% off first permanently</p>
                                <p className='benefits-text'>20% off coupon</p>
                                <p className='benefits-text'>30% off all digital artwork permanently</p>
                                
                            </div>
                        </Col>

                        <Col md={3} className='work-block'>
                            <div className='work-item'>
                                <img src={tier4} className='tier-image' alt=''/>
                                <p className='benefits-heading'>Tier 4: Oculist 1000 points</p>

                                <p className='benefits-text'>50 free points</p>
                                <p className='benefits-text'>15% off permanently</p>
                                <p className='benefits-text'>30% off coupon</p>
                                <p className='benefits-text'>Permanent free shipping</p>
                                <p className='benefits-text'>50% off all digital artwork permanently</p>
                                <p className='benefits-text'>Invitation to exclusive events</p>
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='bg-black member_section-3'>
                <Container>
                    <Col>
                        <div >
                            <h1 className='text-white text-center'>Ways to Earn Points</h1>
                        </div>
                    </Col>
                </Container>
            </section>

            <section className='member_section-3'>
                <div className='m-0 p-0'>
                    <div className='section4-wrapper'>
                        <div className='points_method-wrapper'>
                            <div className='point-method'>
                                <i class="ri-user-line"></i>
                                <h3>10 Points</h3>
                                <div className='point-text'><p>Create an Account</p></div>
                            </div>

                            <div className='point-method'>
                                <i class="ri-shopping-cart-2-line"></i>
                                <h3>1 Point</h3>
                                <div className='point-text'><p>Per $1 spent</p></div>
                            </div>

                            <div className='point-method'>
                                <i class="ri-file-text-line"></i>
                                <h3>10 Points</h3>
                                <div className='point-text'><p>Sign up for Brotherhood Texts</p></div>
                            </div>
                        </div>

                        <div className='points_method-wrapper'>
                            <div className='point-method'>
                                <i class="ri-mail-line"></i>
                                <h3>10 Points</h3>
                                <div className='point-text'><p>Sign up for Brotherhood emails</p></div>
                            </div>

                            <div onClick={() => window.open('https://www.facebook.com')} className='point-method' style={{ cursor: 'pointer' }}>
                                <i class="ri-facebook-fill"></i>
                                <h3>5 Points</h3>
                                <div className='point-text'><p>Follow our Facebook with the provided link</p></div>
                            </div>

                            
                            <div onClick={() => window.open('https://www.instagram.com')} className='point-method' style={{ cursor: 'pointer' }}>
                                <i class="ri-instagram-line"></i>
                                <h3>5 Points</h3>
                                <div className='point-text'><p>Follow our Instagram with the provided link</p></div>
                            </div>
                         
                        </div>
                    </div> 
                </div>
            </section>
        </div>

  </Helmet>
}

export default Brotherhood